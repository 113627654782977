<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>单品活动</el-breadcrumb-item>
        <el-breadcrumb-item>{{ mybreadcrumb }}</el-breadcrumb-item>
        <el-breadcrumb-item>新增活动</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div style="padding: 50px; padding-right: 10%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 买送活动 -->
        <div v-if="mybreadcrumb == '买送活动'">
          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动规则：" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="活动商品：" prop="multipleSelections">
            <div style="display: flex">
              <div style="margin-right: 8px">
                <i class="el-icon-plus"></i
                ><a href="#" @click="dialogVisible = true">添加商品</a>
              </div>
              <div>
                <i class="el-icon-delete"></i
                ><a href="#" @click="isStoreClick">删除商品</a>
              </div>
            </div>
            <div
              ref="isStore"
              v-for="multipleSelection in ruleForm.multipleSelections"
              :key="multipleSelection.id"
            >
              {{ multipleSelection.product_name }}
            </div>
          </el-form-item>
          <el-form-item label="活动档位：" prop="tables">
            <div>
              <table class="table" style="border: 1px solid #e5e5e5">
                <tr>
                  <th>购买数量</th>
                  <th>赠送数量</th>
                  <th></th>
                </tr>
                <tr v-for="(tab, index) in ruleForm.tables" :key="index">
                  <td>
                    <el-form-item
                      :prop="'tables.' + index + '.buyNumber'"
                      :rules="{
                        required: true,
                        message: '购买数量不能为空',
                        trigger: 'blur',
                      }"
                      ><el-input v-model="tab.buyNumber"></el-input
                    ></el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'tables.' + index + '.giftNumber'"
                      :rules="{
                        required: true,
                        message: '赠送数量不能为空',
                        trigger: 'blur',
                      }"
                      ><el-input v-model="tab.giftNumber"></el-input
                    ></el-form-item>
                  </td>
                  <td>
                    <i
                      class="el-icon-delete"
                      @click="tableClickClose(index)"
                    ></i>
                  </td>
                </tr>
              </table>
              <el-button
                type="primary"
                style="margin-top: 15px"
                @click="tableClickOpen"
                >添加档位</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="有效期(天):" prop="period">
            <el-input
              v-model="ruleForm.period"
              placeholder="0表示不限天数"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间：" required style="width: 70%">
            <el-col :span="8">
              <el-form-item prop="time1">
                <el-date-picker
                  v-model="ruleForm.time1"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="8">
              <el-form-item prop="time2">
                <el-date-picker
                  v-model="ruleForm.time2"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4" style="margin-left: 20px"
              ><el-checkbox v-model="ruleForm.long" @change="longChange"
                >长期活动</el-checkbox
              ></el-col
            >
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="活动库存："
              prop="inventory"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.inventory"
                placeholder="必填"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限购："
              prop="limitation"
              style="width: 25%"
            >
              <el-input v-model="ruleForm.limitation"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="活动对象：" prop="objects">
            <el-checkbox-group v-model="ruleForm.objects">
              <el-checkbox
                :label="index"
                name="objects"
                v-for="(obj, index) in objects"
                :key="index"
                >{{ obj }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="活动类型：" prop="types">
            <el-checkbox-group v-model="ruleForm.types">
              <el-checkbox label="0" name="types">个人用户</el-checkbox>
              <el-checkbox label="1" name="types">企业用户</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="" prop="null">
            <el-checkbox v-model="ruleForm.activityOverlap"
              >允许活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.newUser">只限新用户生效</el-checkbox>
          </el-form-item>
          <el-form-item label="排序" prop="srot" style="width: 25%">
            <el-input v-model="ruleForm.srot"></el-input>
          </el-form-item>
        </div>
        <!-- 买赠活动 -->
        <div v-if="mybreadcrumb == '买赠活动'">
          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动规则：" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="活动商品：" prop="store">
            <div style="display: flex">
              <div style="margin-right: 8px">
                <i class="el-icon-plus"></i
                ><a href="#" @click="dialogVisible = true">添加商品</a>
              </div>
              <div>
                <i class="el-icon-delete"></i
                ><a href="#" @click="isStoreClick">删除商品</a>
              </div>
            </div>
            <div
              ref="isStore"
              v-for="multipleSelection in ruleForm.multipleSelections"
              :key="multipleSelection.id"
            >
              {{ multipleSelection.product_name }}
            </div>
          </el-form-item>
          <el-form-item label="赠品：" prop="gift" style="width: 200%">
            <el-select v-model="ruleForm.gift" @change="giftChange">
              <el-option
                :label="git.product_name"
                :value="git.id"
                v-for="git in gifts"
                :key="git.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="赠券" prop="coupon" style="width: 200%">
            <el-select v-model="ruleForm.coupon" @change="couponChange">
              <el-option
                :label="cup.wt_name"
                :value="cup.id"
                v-for="cup in coupons"
                :key="cup.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动档位：" prop="tables">
            <div>
              <table class="table" style="border: 1px solid #e5e5e5">
                <tr>
                  <th>购买数量</th>
                  <th>赠送数量</th>
                  <th></th>
                </tr>
                <tr v-for="(tab, index) in ruleForm.tables" :key="index">
                  <td>
                    <el-form-item
                      :prop="'tables.' + index + '.buyNumber'"
                      :rules="{
                        required: true,
                        message: '购买数量不能为空',
                        trigger: 'blur',
                      }"
                      ><el-input v-model="tab.buyNumber"></el-input
                    ></el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :prop="'tables.' + index + '.giftNumber'"
                      :rules="{
                        required: true,
                        message: '赠送数量不能为空',
                        trigger: 'blur',
                      }"
                      ><el-input v-model="tab.giftNumber"></el-input
                    ></el-form-item>
                  </td>
                  <td>
                    <i
                      class="el-icon-delete"
                      @click="tableClickClose(index)"
                    ></i>
                  </td>
                </tr>
              </table>
              <el-button
                type="primary"
                style="margin-top: 15px"
                @click="tableClickOpen"
                >添加档位</el-button
              >
            </div>
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="次限可用量："
              prop="available"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.available"
                placeholder="0代表不限"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="激活间隔天："
              prop="interval"
              style="width: 25%; margin-left: 60px"
            >
              <el-input
                v-model="ruleForm.interval"
                placeholder="0代表不限"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="活动时间：" required style="width: 70%">
            <el-col :span="8">
              <el-form-item prop="time1">
                <el-date-picker
                  v-model="ruleForm.time1"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="8">
              <el-form-item prop="time2">
                <el-date-picker
                  v-model="ruleForm.time2"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4" style="margin-left: 20px"
              ><el-checkbox v-model="ruleForm.long" @change="longChange"
                >长期活动</el-checkbox
              ></el-col
            >
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="活动库存："
              prop="inventory"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.inventory"
                placeholder="必填"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限购："
              prop="limitation"
              style="width: 25%"
            >
              <el-input v-model="ruleForm.limitation"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="活动对象：" prop="objects">
            <el-checkbox-group v-model="ruleForm.objects">
              <el-checkbox
                :label="obj"
                name="objects"
                v-for="(obj, index) in objects"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="活动类型：" prop="types">
            <el-checkbox-group v-model="ruleForm.types">
              <el-checkbox label="个人用户" name="types"></el-checkbox>
              <el-checkbox label="企业用户" name="types"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="" prop="null">
            <el-checkbox v-model="ruleForm.activityOverlap"
              >允许活动重叠生效</el-checkbox
            >
            <el-checkbox v-model="ruleForm.newUser">只限新用户生效</el-checkbox>
          </el-form-item>
          <el-form-item label="排序" prop="srot" style="width: 25%">
            <el-input v-model="ruleForm.srot"></el-input>
          </el-form-item>
        </div>
        <!-- 首单价活动 -->
        <div v-if="mybreadcrumb == '首单价活动'">
          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动规则：" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="活动商品：" prop="store">
            <div style="display: flex">
              <div style="margin-right: 8px">
                <i class="el-icon-plus"></i
                ><a href="#" @click="dialogVisible = true">添加商品</a>
              </div>
              <div>
                <i class="el-icon-delete"></i
                ><a href="#" @click="isStoreClick">删除商品</a>
              </div>
            </div>
            <div
              ref="isStore"
              v-for="multipleSelection in ruleForm.multipleSelections"
              :key="multipleSelection.id"
            >
              {{ multipleSelection.product_name }}
            </div>
          </el-form-item>
          <el-form-item label="活动价：" prop="activePrice">
            <el-input v-model="ruleForm.activePrice"></el-input>
          </el-form-item>
          <el-form-item label="活动时间：" required style="width: 70%">
            <el-col :span="8">
              <el-form-item prop="time1">
                <el-date-picker
                  v-model="ruleForm.time1"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="8">
              <el-form-item prop="time2">
                <el-date-picker
                  v-model="ruleForm.time2"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4" style="margin-left: 20px"
              ><el-checkbox v-model="ruleForm.long" @change="longChange"
                >长期活动</el-checkbox
              ></el-col
            >
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="活动库存："
              prop="inventory"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.inventory"
                placeholder="必填"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限购："
              prop="limitation"
              style="width: 25%"
            >
              <el-input v-model="ruleForm.limitation"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="起订量：" prop="orderQuantity">
            <el-input v-model="ruleForm.orderQuantity"></el-input>
          </el-form-item>
          <el-form-item label="活动对象：" prop="objects">
            <el-checkbox-group v-model="ruleForm.objects">
              <el-checkbox
                :label="obj"
                name="objects"
                v-for="(obj, index) in objects"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="排序" prop="srot" style="width: 25%">
            <el-input v-model="ruleForm.srot"></el-input>
          </el-form-item>
        </div>
        <!-- 单品券活动 -->
        <div v-if="mybreadcrumb == '单品券活动'">
          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动规则：" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="活动商品：" prop="store">
            <div style="display: flex">
              <div style="margin-right: 8px">
                <i class="el-icon-plus"></i
                ><a href="#" @click="dialogVisible = true">添加商品</a>
              </div>
              <div>
                <i class="el-icon-delete"></i
                ><a href="#" @click="isStoreClick">删除商品</a>
              </div>
            </div>
            <div
              ref="isStore"
              v-for="multipleSelection in ruleForm.multipleSelections"
              :key="multipleSelection.id"
            >
              {{ multipleSelection.product_name }}
            </div>
          </el-form-item>
          <el-form-item label="抵扣金额：" prop="deduction">
            <el-input v-model="ruleForm.deduction"></el-input>
          </el-form-item>
          <el-form-item label="有效期天：" prop="indate" style="width: 25%">
            <el-input
              v-model="ruleForm.indate"
              placeholder="0代表不限天数"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间：" required style="width: 70%">
            <el-col :span="8">
              <el-form-item prop="time1">
                <el-date-picker
                  v-model="ruleForm.time1"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="8">
              <el-form-item prop="time2">
                <el-date-picker
                  v-model="ruleForm.time2"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4" style="margin-left: 20px"
              ><el-checkbox v-model="ruleForm.long" @change="longChange"
                >长期活动</el-checkbox
              ></el-col
            >
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="活动库存："
              prop="inventory"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.inventory"
                placeholder="必填"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限购："
              prop="limitation"
              style="width: 25%"
            >
              <el-input v-model="ruleForm.limitation"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="活动对象：" prop="objects">
            <el-checkbox-group v-model="ruleForm.objects">
              <el-checkbox
                :label="obj"
                name="objects"
                v-for="(obj, index) in objects"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="排序" prop="srot" style="width: 25%">
            <el-input v-model="ruleForm.srot"></el-input>
          </el-form-item>
        </div>
        <!-- 买充活动 -->
        <div v-if="mybreadcrumb == '买充活动'">
          <el-form-item label="活动名称：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动规则：" prop="Monetary">
            <el-input v-model="ruleForm.Monetary"></el-input>
          </el-form-item>
          <el-form-item label="活动商品：" prop="store">
            <div style="display: flex">
              <div style="margin-right: 8px">
                <i class="el-icon-plus"></i
                ><a href="#" @click="dialogVisible = true">添加商品</a>
              </div>
              <div>
                <i class="el-icon-delete"></i
                ><a href="#" @click="isStoreClick">删除商品</a>
              </div>
            </div>
            <div
              ref="isStore"
              v-for="multipleSelection in ruleForm.multipleSelections"
              :key="multipleSelection.id"
            >
              {{ multipleSelection.product_name }}
            </div>
          </el-form-item>
          <el-form-item label="活动档位：" prop="tables">
            <div>
              <table class="table" style="border: 1px solid #e5e5e5">
                <tr>
                  <th>购买数量</th>
                  <th></th>
                </tr>
                <tr v-for="(tab, index) in ruleForm.tables" :key="index">
                  <td>
                    <el-form-item
                      :prop="'tables.' + index + '.buyNumber'"
                      :rules="{
                        required: true,
                        message: '购买数量不能为空',
                        trigger: 'blur',
                      }"
                      ><el-input v-model="tab.buyNumber"></el-input
                    ></el-form-item>
                  </td>
                  <td>
                    <i
                      class="el-icon-delete"
                      @click="tableClickClose(index)"
                    ></i>
                  </td>
                </tr>
              </table>
              <el-button
                type="primary"
                style="margin-top: 15px"
                @click="tableClickOpen"
                >添加档位</el-button
              >
            </div>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="充值金额：" prop="recharge" style="width: 25%">
              <el-input v-model="ruleForm.recharge"></el-input>
            </el-form-item>
            <el-form-item label="升级至：" prop="upgrade">
              <el-select
                v-model="ruleForm.upgrade"
                style="width: 38% !important"
              >
                <el-option
                  :label="upg"
                  :value="index"
                  v-for="(upg, index) in upgrades"
                  :key="upg"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="有效期天：" prop="indate" style="width: 25%">
            <el-input
              v-model="ruleForm.indate"
              placeholder="0代表不限天数"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间：" required style="width: 70%">
            <el-col :span="8">
              <el-form-item prop="time1">
                <el-date-picker
                  v-model="ruleForm.time1"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="8">
              <el-form-item prop="time2">
                <el-date-picker
                  v-model="ruleForm.time2"
                  type="datetime"
                  placeholder="选择日期时间"
                  align="right"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4" style="margin-left: 20px"
              ><el-checkbox v-model="ruleForm.long" @change="longChange"
                >长期活动</el-checkbox
              ></el-col
            >
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="活动库存："
              prop="inventory"
              style="width: 25%"
            >
              <el-input
                v-model="ruleForm.inventory"
                placeholder="必填"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="每人限购："
              prop="limitation"
              style="width: 25%"
            >
              <el-input v-model="ruleForm.limitation"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="活动对象：" prop="objects">
            <el-checkbox-group v-model="ruleForm.objects">
              <el-checkbox
                :label="obj"
                name="objects"
                v-for="(obj, index) in objects"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="排序" prop="srot" style="width: 25%">
            <el-input v-model="ruleForm.srot"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <AddProduct
      :productShow="dialogVisible"
      @addConfirm="addConfirm"
      @handleClose="handleClose"
    />
  </div>
</template>
<script>
import qs from "qs";
import { urlList } from "./data";
import AddProduct from "../../../../../addProduct/addProduct.vue";
import { getDate } from "./utilse";
import axios from "axios";
export default {
  props: ["breadcrumb", "data"],
  components: { AddProduct },
  data() {
    const productSelect = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择商品"));
      } else {
        callback();
      }
    };
    const tablesSelect = (rule, value, callback) => {
      console.log(value);
      if (value.length == 0) {
        callback(new Error("请添加活动档位"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        Monetary: "",
        store: [],
        time1: "",
        time2: "",
        inventory: "",
        limitation: "0",
        objects: [],
        types: [],
        null: [],
        srot: "",
        gift: "",
        giftId: "",
        coupon: "",
        couponId: "",
        available: "",
        interval: "",
        activePrice: "",
        deduction: "",
        indate: "",
        recharge: "",
        upgrade: "vip0",
        tables: [],
        multipleSelections: [],
        period: "",
        long: false,
        activityOverlap: false,
        newUser: false,
        orderQuantity: "",
      },

      rules: {
        name: [{ required: true, trigger: "blur", message: "这是必填字段" }],
        Monetary: [
          { required: true, trigger: "blur", message: "这是必填字段" },
        ],
        multipleSelections: [
          { validator: productSelect, trigger: "blur", required: true },
        ],
        tables: [{ validator: tablesSelect, trigger: "blur", required: true }],
        time1: [{ required: true, trigger: "blur", message: "这是必填字段" }],
        time2: [{ required: true, trigger: "blur", message: "这是必填字段" }],
        inventory: [
          { required: true, trigger: "blur", message: "这是必填字段" },
        ],
        deduction: [
          { required: true, trigger: "blur", message: "这是必填字段" },
        ],
        indate: [{ required: true, trigger: "blur", message: "这是必填字段" }],
        recharge: [
          { required: true, trigger: "blur", message: "这是必填字段" },
        ],
        upgrade: [{ required: true, trigger: "blur", message: "这是必填字段" }],
        period: [{ required: true, trigger: "blur", message: "这是必填字段" }],
      },
      id: 0,
      // 不同活动的新增

      objects: ["vip0", "vip1", "vip2", "vip3", "vip4", "vip5"],
      gifts: [],
      coupons: [],
      upgrades: ["vip0", "vip1", "vip2", "vip3", "vip4", "vip5"],

      // 弹窗
      dialogVisible: false,
      baseUrl: "",
      type: "",
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.baseUrl = item.url;
        this.type = item.type;
      }
    });
    if (this.$props.data) {
      const row = this.$props.data;
      this.id = row.id;
      this.ruleForm.name = row.activity_name;
      this.ruleForm.Monetary = row.activity_rule;
      this.ruleForm.multipleSelections = row.product_arr;
      this.ruleForm.period = row.period_validity;
      this.ruleForm.time1 = row.activity_star_day;
      this.ruleForm.time2 = row.activity_end__day;
      this.ruleForm.long = row.long_term == 0 ? false : true;
      this.ruleForm.inventory = row.activity_inventory;
      this.ruleForm.limitation = row.limited_purchase;
      this.ruleForm.objects = row.activity_object;
      this.ruleForm.activityOverlap = row.activity_overlap == 0 ? false : true;
      this.ruleForm.newUser = row.new_user_overlap == 0 ? false : true;
      this.ruleForm.srot = row.sort;
      this.ruleForm.tables = row.activity_gear;
      this.ruleForm.types = row.activity_type_arr;
      this.ruleForm.available = row.limited_quantity;
      this.ruleForm.interval = row.Interval_days;
      this.ruleForm.activePrice = row.activity_price;
      this.ruleForm.orderQuantity = row.order_quantity;
      this.ruleForm.deduction = row.offset_the_cost;
      this.ruleForm.indate = row.period_validity;
      this.ruleForm.upgrade = row.upgrade_to;
      this.ruleForm.recharge = row.recharge_amount;
    }
    if (this.$props.data && this.$props.breadcrumb == "买赠活动") {
      const row = this.$props.data;
      this.ruleForm.gift = row.giveaway[0].product_name;
      this.ruleForm.giftId = row.giveaway[0].id;
      this.ruleForm.coupon = row.give_ticket[0].wt_name;
      this.ruleForm.couponId = row.give_ticket[0].id;
    }
    if (this.$props.breadcrumb == "买赠活动") {
      axios
        .all([
          this.$http.post(
            "/product/lst",
            qs.stringify({
              currentPage: 0,
              currentLength: 0,
              commercial_id: this.commercial_id,
            })
          ),
          this.$http.post(
            "/water_ticket/lst",
            qs.stringify({ currentPage: 0, currentLength: 0 })
          ),
        ])
        .then(
          axios.spread((res1, res2) => {
            this.gifts = res1.data.data;
            this.coupons = res2.data.data;
          })
        );
    }
  },
  computed: {
    mybreadcrumb() {
      return this.breadcrumb;
    },
  },
  methods: {
    closeClick() {
      this.$emit("close", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const subObj = {
            id: this.id,
            activity_name: this.ruleForm.name,
            activity_rule: this.ruleForm.Monetary,
            activity_star_day: getDate(this.ruleForm.time1),
            activity_end__day: getDate(this.ruleForm.time2),
            long_term: this.ruleForm.long ? "1" : "0",
            activity_inventory: this.ruleForm.inventory,
            limited_purchase: this.ruleForm.limitation,
            activity_object: JSON.stringify(this.ruleForm.objects),
            activity_type: this.type,
            sort: this.ruleForm.srot,
          };
          if (this.$props.breadcrumb == "买送活动") {
            subObj.period_validity = this.ruleForm.period;
            subObj.product_arr = this.ruleForm.multipleSelections;
            subObj.activity_type_arr = JSON.stringify(this.ruleForm.types);
            subObj.activity_gear = JSON.stringify(this.ruleForm.tables);
            subObj.activity_overlap = this.ruleForm.activityOverlap ? "1" : "0";
            subObj.new_user_overlap = this.ruleForm.newUser ? "1" : "0";
          } else if (this.$props.breadcrumb == "买赠活动") {
            subObj.limited_quantity = this.ruleForm.available;
            subObj.giveaway = this.ruleForm.giftId;
            subObj.give_ticket = this.ruleForm.couponId;
            subObj.Interval_days = this.ruleForm.interval;
            subObj.product_arr = this.ruleForm.multipleSelections;
            subObj.activity_type_arr = JSON.stringify(this.ruleForm.types);
            subObj.activity_gear = JSON.stringify(this.ruleForm.tables);
            subObj.activity_overlap = this.ruleForm.activityOverlap ? "1" : "0";
            subObj.new_user_overlap = this.ruleForm.newUser ? "1" : "0";
          } else if (this.$props.breadcrumb == "首单价活动") {
            subObj.activity_price = this.ruleForm.activePrice;
            subObj.product_arr = this.ruleForm.multipleSelections[0].id;
            subObj.order_quantity = this.ruleForm.orderQuantity;
          } else if (this.$props.breadcrumb == "单品券活动") {
            subObj.product_arr = this.ruleForm.multipleSelections[0].id;
            subObj.offset_the_cost = this.ruleForm.deduction;
            subObj.period_validity = this.ruleForm.indate;
          } else if (this.$props.breadcrumb == "买充活动") {
            subObj.product_arr = this.ruleForm.multipleSelections[0].id;
            subObj.recharge_amount = this.ruleForm.recharge;
            subObj.activity_gear = JSON.stringify(this.ruleForm.tables);
            subObj.upgrade_to = this.ruleForm.upgrade;
            subObj.period_validity = this.ruleForm.indate;
          }
          this.$http
            .post(`${this.baseUrl}/save`, qs.stringify(subObj))
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close", true);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        } else {
          this.$message({
            message: "请将表单填写完整",
            type: "warning",
          });
          return false;
        }
      });
    },
    giftChange(val) {
      this.ruleForm.giftId = val;
    },
    couponChange(val) {
      this.ruleForm.couponId = val;
    },
    addConfirm(arr) {
      if (
        this.$props.breadcrumb == "首单价活动" ||
        this.$props.breadcrumb == "单品券活动" ||
        this.$props.breadcrumb == "买充活动"
      ) {
        this.ruleForm.multipleSelections = [arr[arr.length - 1]];
      } else {
        this.ruleForm.multipleSelections = arr;
      }
      this.dialogVisible = false;
    },
    onSubmit() {
      console.log("submit!");
    },
    csclick(data) {
      console.log(data);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    longChange(val) {
      if (val) {
        this.ruleForm.time1 = new Date();
        this.ruleForm.time2 = new Date(2099, 11, 31, 23, 59, 59);
      } else {
        this.ruleForm.time2 = "";
      }
    },
    tableClickClose(i) {
      this.ruleForm.tables.splice(i, 1);
    },
    tableClickOpen() {
      if (this.$props.breadcrumb == "买充活动") {
        this.ruleForm.tables.push({ buyNumber: "" });
      } else {
        this.ruleForm.tables.push({ buyNumber: "", giftNumber: "" });
      }
    },
    isStoreClick() {
      this.ruleForm.multipleSelections = [];
    },
    dialogT() {
      this.dialogVisible = false;
    },
    pickerOptions() {
      console.log("pickerOptions");
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  color: #333;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.dialogSelect {
  height: 32px;
}

.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.label {
  background-color: #ffeabf;
}

.table {
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  width: 50%;

  tr {
    th {
      border: 1px solid #e5e5e5;
    }

    td {
      border: 1px solid #e5e5e5;
      padding: 10px;
    }
  }
}
</style>

<style lang="less" scoped>
.el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}

.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}
</style>
